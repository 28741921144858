<template>
  <light-timeline v-if="items" :items="items"></light-timeline>
</template>
<script>
export default {
  name: "TrackingComponent",
  props: {
    items: null,
  },
};
</script>
<template>
  <div>
    <section class="section is-main-section">
      <h2 class="title">ຕິດຕາມເຄື່ອງ - {{ code }}</h2>
      <div class="columns" v-if="items">
        <div class="column"><TrackingComponent :items="item.trackings" /></div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import TrackingComponent from "../components/TrackingComponent.vue";
export default {
  name: "Tracking",
  components: { TrackingComponent },
  data() {
    return {
      code: null,
      item: null,
    };
  },
  mounted() {
    this.code = this.$route.params["code"];
    if (this.code) {
      axios.get("tracking/get/" + this.code).then((r) => {
        this.item = r;
        if (r && r.trackings) {
          this._.map(r.trackings, (i) => {
            i.tag = i.date;
            i.htmlMode = true;
            i.type = "circle";
            i.color = "green";
            i.content = `
                <div>
                  <div style='font-size: 1.5em'>${i.title}</div>
                  <div style='font-size: 1em;color: #777777'>${i.description}</div>
                </div>
                `;
            this.trackings = r.trackings;
          });
        }
      });
    }
  },
};
</script>